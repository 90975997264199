import React, { Component } from "react";
import "./App.sass";
import Header from "./Header";
import Main from "./Main";
import SidebarProvider from "../store/SidebarProvider";
import StreamInfoContext from "../store/StreamInfoContext";
import ChatProvider from "../store/ChatProvider";
import FreshChat from "react-freshchat";
import { Helmet } from "react-helmet";

class App extends Component {
  state = {
    stream_info: {
      title: "",
      header_title: "",
      yt_url: "",
      from_start: false,
      left_sidebar_opened: false,
      right_sidebar_opened: false,
      show_program: "",
      hide_program: "",
      show_exhibition: "",
      hide_exhibition: "",
      directory_button: "",
      directory_href: `javascript:history.back()`,
      support_button: "",
      extra_sidebar: {
        opened: false,
        side_button: false,
        frame_url: "",
        width: 300,
        height: "100%",
        button_width: 12,
        button_text_opened: ">",
        button_text_closed: "<",
      },
    },
    chatHandlers: null,
    firstFetch: true,
    extra_sidebar_opened: null,
  };
  componentDidMount() {
    this.fetchLiveStream();
  }

  fetchLiveStream = () => {
    fetch("LiveStream.json", { cache: "no-cache" })
      .then((response) => response.json())
      .then(this.dataReceived)
      .then(this.afterFetch)
      .then(this.timeOut)
      .then(this.fetchLiveStream);
  };

  timeOut = () =>
    new Promise((resolve) => {
      window.setTimeout(resolve, 3000);
    });

  dataReceived = (data) =>
    new Promise((resolve) => {
      const { stream_info, extra_sidebar_opened } = this.state;
      if (data["yt_url"] === stream_info["yt_url"]) resolve();

      this.setState(
        {
          stream_info: { ...stream_info, ...data },
          extra_sidebar_opened:
            extra_sidebar_opened === null
              ? data.extra_sidebar && data.extra_sidebar.opened
              : extra_sidebar_opened,
        },
        resolve
      );
    });

  afterFetch = () => {
    const { stream_info, firstFetch } = this.state;
    if (!firstFetch) return;

    document.title = stream_info["title"];
    this.setState({ firstFetch: false });
  };

  switchExtraSidebar = () => {
    const { extra_sidebar_opened } = this.state;
    this.setState({ extra_sidebar_opened: !extra_sidebar_opened });
  };

  render() {
    const {
      stream_info,
      chatHandlers,
      stream_info: { extra_sidebar },
      extra_sidebar_opened,
    } = this.state;
    const {
      stream_info: {
        extra_sidebar: { side_button },
      },
    } = this.state;
    const sidebar_defaults = {
      leftSidebarOpened: stream_info["left_sidebar_opened"],
      rightSidebarOpened: stream_info["right_sidebar_opened"],
    };

    return (
      <>
        <Helmet>
          <title>{stream_info.title}</title>
        </Helmet>
        <div className="app">
          <SidebarProvider defaultState={sidebar_defaults}>
            <ChatProvider chatHandlers={chatHandlers}>
              <StreamInfoContext.Provider value={stream_info}>
                <Header switchExtraSidebar={this.switchExtraSidebar} />
                <Main />
              </StreamInfoContext.Provider>
            </ChatProvider>
          </SidebarProvider>

          <FreshChat
            token={"fabf92dd-337b-4aed-b1cd-3926c45e7cf1"}
            host={"https://wchat.eu.freshchat.com"}
            config={{ cssNames: { widget: "fc_frame", open: "fc_open" } }}
            onInit={(widget) => {
              widget.hide();
              widget.close();
              this.setState({ chatHandlers: widget });
            }}
          />
        </div>
        {extra_sidebar.frame_url && (
          <aside
            className="sidebar extra-sidebar"
            style={{
              width: extra_sidebar_opened
                ? extra_sidebar.width
                : side_button && extra_sidebar.button_width,
            }}
          >
            {extra_sidebar.side_button && (
              <button
                className="extra-sidebar-button"
                style={{ minWidth: extra_sidebar.button_width }}
                onClick={this.switchExtraSidebar}
              >
                {" "}
                {extra_sidebar_opened
                  ? extra_sidebar.button_text_opened
                  : extra_sidebar.button_text_closed}{" "}
              </button>
            )}
            <iframe
              title="extra-sidebar"
              src={extra_sidebar.frame_url}
              width="100%"
              height={extra_sidebar.height}
            />
          </aside>
        )}
      </>
    );
  }
}

export default App;
